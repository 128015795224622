body {
  background: var(--sdds-grey-50);
}
.sdds-container {
  margin-top: var(--sdds-header-height);
}
.content-wrapper {
  padding-top: var(--sdds-spacing-element-24);
  padding-bottom: var(--sdds-spacing-element-24);
}

.lead-head {
  margin-bottom: var(--sdds-spacing-element-16);
}

.section-wrapper {
  margin-top: var(--sdds-spacing-element-32);
}

.component-wrapper {
  padding-bottom: var(--sdds-spacing-element-24);
}
.sdds-btn-group  .sdds-btn,
.sdds-btn-group sdds-button {
    margin: 10px;
  }

form > div {
  margin-bottom: var(--sdds-spacing-element-24);
}

pre {
  font-family: Consolas,Menlo,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New,monospace,serif;
  margin-bottom: 10px;
  margin-top: 0;
  overflow-x: auto;
  width: auto;
}

.Toastify__progress-bar{
  background: #4A89F3 !important;
}

.sdds-navbar{
  z-index: 10001;
}